<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { httpPurchaseListList, updateInstoreStatus,httpPurchaseListListSummary } from '@/api/purchase/index'
import { listSupplier } from '@/api/goods/supplier' //供应商
import { allEmployeeList } from '@/api/system/employee' //员工
import { allStoreList } from '@/api/system/store' //仓库
export default {
  components: { TablePage },
  dicts: ['bill_status'],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatus: to.query.billStatus };
        vm.options.search[4].model = to.query.billStatus
        vm.options.search[1].defaultValue = ''
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        exportOption: {
          show: true,
          exportApi: 'shopPurchaseExportList',
          exportName: '门店采购列表',
          timeout: 60*5*1000
        },
        getListApi: httpPurchaseListList,
        getSummaryApi: httpPurchaseListListSummary,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        check: [], // 选中数据
        rowKey: 'billId',
        search: [
          {
            label: '单据日期',
            type: 'daterange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' })
          },
          {
            keyIndex: 0,
            format: 'daterange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: '单据编号',
            seniorSearch: true,
            type: 'input',
            model: '',
            filter: 'billNo'
          },
          {
            label: '供应商',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'partnerId',
            option: {
              remote: listSupplier,
              dataKey: 'rows',
              label: 'partnerName',
              value: 'partnerId',
              filterable: true
            }
          },
          {
            label: '单据状态',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'billStatus',
            option: {
              remote: this.getDicts,
              remoteBody: 'bill_status',
              dataKey: 'data',
              label: 'dictLabel',
              value: 'dictValue',
              filterable: true
            }
          },
          {
            label: '仓库',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'storeId',
            option: {
              remote: allStoreList,
              dataKey: 'data',
              label: 'storeName',
              value: 'storeId',
              filterable: true
            }
          },
          {
            label: '采购员',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'purEmployeeId',
            option: {
              remote: allEmployeeList,
              dataKey: 'data',
              label: 'employeeName',
              value: 'employeeId',
              filterable: true
            }
          },
          {
            label: '创建人',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'createEmployeeId',
            option: {
              remote: allEmployeeList,
              dataKey: 'data',
              label: 'employeeName',
              value: 'employeeId',
              filterable: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            disabled: false
          },
          {
            click: 'copy',
            label: '复制',
            icon: 'el-icon-document-copy',
            type: 'success',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            btnType: 'dropdown',
            other: [
              {
                click: 'redoAudit',
                label: '反审核'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'stop',
            label: '终止',
            icon: 'el-icon-remove-outline',
            type: 'primary',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'routerLink',
            minWidth: 180
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 155
          },
          {
            prop: 'billStatus',
            label: '单据状态',
            type: 'dict',
            minWidth: 120,
            dict: 'bill_status'
          },
          {
            prop: 'billQty',
            label: '单据数量',
            minWidth: 100
          },
          {
            prop: 'billTaxMoney',
            label: '单据金额(元)',
            minWidth: 140
          },
          {
            prop: 'partnerName',
            label: '供应商',
            minWidth: 160
          },
          {
            prop: 'inStoreName',
            label: '仓库',
            minWidth: 160
          },
          {
            prop: 'handBillNo',
            label: '手工单据号',
            minWidth: 120
          },
          {
            prop: 'deptName',
            label: '部门',
            minWidth: 160
          },
          {
            prop: 'purEmployeeName',
            label: '采购员',
            minWidth: 160
          },
          {
            prop: 'billRemark',
            label: '备注',
            minWidth: 160
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 160
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 160
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160
          }
        ],
        summary: ['billTaxMoney'],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      let url = '/api/system/shop/bill/purchase/updateBillStatus'
      let billType = '140303'
      switch (type) {
        case 'routerLink':
          {
            const billId = row.billId
            this.$router.push({
              name: 'StockInDetail',
              query: {
                billId: billId,
                type: 'Update'
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: 'StockInDetail',
              query: {
                type: 'Add'
              }
            })
          }
          break
        case 'copy':
          {
            if (!selectData.length) return
            this.handleBillStatus('复制', selectData, '/api/system/shop/bill/purchase/copy', billType)
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData, url, billType)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData, url, billType)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData, url, billType)
          }
          break
        case 'stop':
          {
            if (!selectData.length) return
            this.handleBillStatus('关闭', selectData, url, billType)
          }
          break
        default:
          break
      }
    },
    handleBillStatus (command, selectData, url, BillType, type) {
      let billData = [] //过滤的数据
      let name = '' //要执行的状态名字
      let tag = '' //提示的状态
      let billStatus = '' //要执行的状态数字
      if (command === '反审核') {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = '反审核'
        tag = '已审核'
        billStatus = '0'
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === '审核') {
          name = '审核'
          tag = '未审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          tag = '未审核'
          billStatus = '3'
        } else if (command === '关闭') {
          name = '关闭'
          tag = '未审核'
          billStatus = '4'
        }
      }
      const billId = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus(
            [...billId],
            billStatus,
            url,
            BillType
          ).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
